import request from '@/utils/request'
// 卡券列表
export function getTableList(data) {
  return request('post', '/apm/cardCoupon/cardList', data)
}

// 卡券排序
export function getTableSort(data) {
  return request('post', '/apm/cardCoupon/changeSort', data)
}

// 卡券上下架
export function getTableUpDown(data) {
  return request('post', '/apm/cardCoupon/changeStatus', data)
}

// 卡券操作记录
export function getOperateList(data) {
  return request('post', '/apm/cardCoupon/operateList', data)
}

// 卡券房间列表
export function getCardRoomList(data) {
  return request('post', '/apm/room/roomListByCard', data)
}

// 卡券新增
export function getCardEdit(data) {
  return request('post', '/apm/cardCoupon/create', data)
}

// 卡券删除
export function goDeleteCard(data) {
  return request('post', '/apm/cardCoupon/del', data)
}

// 卡券编辑时查详情
export function getCardDetail(data) {
  return request('post', '/apm/cardCoupon/findById', data)
}

// 卡券编辑
export function goUpdateCard(data) {
  return request('post', '/apm/cardCoupon/update', data)
}

// 平台卡券订单
export function getPlateformTableList(data) {
  return request('post', '/apm/sp/cardCoupon/card/order', data)
}

// 卡券核销
export function cardWriteOff(data) {
  return request('post', '/apm/sp/cardCoupon/cardWriteOff', data)
}
// 卡券核销详情查询
export function writeOffDetails(data) {
  return request('get', '/apm/sp/cardCoupon/writeOffDetails', data)
}

// 平台批量发放卡券
export function batchSend(data) {
  return request('post', '/apm/ums/shop/user/batch/give/card', data)
}
// 发放记录
export function sendRecord(data) {
  return request('post', '/apm/ums/shop/user/cardGiveRecord', data)
}
// 发放记录明细
export function sendRecordDetail(data) {
  return request('get', '/apm/ums/shop/user/cardGiveRecordDetail', data)
}